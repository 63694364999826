<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;" v-if="classifyList && classifyList.length">
            <div slot="header" class="my-header">
                <div class="bold">分类管理</div>
                <div>
                    <el-button type="default" size="mini" @click="addClassify">添加分类</el-button>
                    <el-button size="mini" @click="hasClass=!hasClass">{{hasClass?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasClass?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button>
                </div>
            </div>
            <div v-show="hasClass">
                <template v-for="(v,i) of classifyList">
                    <el-button-group class="m-l-15 m-b-15">
                        <el-button type="" size="mini">{{v.newsAlbumName}}</el-button>
                        <el-button type="" size="mini" icon="el-icon-edit-outline" @click="editClassify(v)"></el-button>
                        <el-button type="" size="mini" icon="el-icon-delete" @click="delMessageBox('delEnterpriseNewsAlbum',v,i)"></el-button>
                    </el-button-group>
                </template>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-bottom: 25px;text-align: center;" v-else>
            <div>
                <img src="@/assets/images/photo/class_not.png">
                <div class="f14 c-2 m-b-20">
                    当前您还没有新闻分类哦！
                </div>
                <el-button type="default" size="mini" @click="addClassify">立即添加</el-button>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-bottom: 25px;" v-if="classifyList && classifyList.length">
            <div slot="header" class="my-header">
                <div class="bold">新闻管理</div>
                <div>
                    <el-select v-model="enterpriseNewsAlbumId" placeholder="请选择" size="mini" class="m-r-10" @change="getAllEnterpriseNewsAlbumItems">
                        <el-option v-for="item in classifyList" :key="item.id" :label="item.newsAlbumName" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="default" size="mini" @click="$oucy.go('/enter/newsRecycle')">回收站</el-button>
                    <el-button type="default" size="mini" @click="$oucy.go('/enter/newsSave')">发布新闻</el-button>
                    <!-- <el-button type="default" size="mini">全部删除</el-button> -->
                    <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
                    <!-- <el-button size="mini">收起<i class="el-icon-caret-top el-icon--right"></i></el-button> -->
                    <el-button size="mini" @click="hasList=!hasList">{{hasList?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasList?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button>
                </div>
            </div>
            <div v-show="hasList">
                <template v-if="newsObj.content">
                    <el-table ref="multipleTable" :data="newsObj.content" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                        <!-- <el-table-column type="selection" width="55">                        </el-table-column> -->
                        <el-table-column prop="itemTital" label="标题" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="发布日期" width="200">
                            <template slot-scope="scope">{{ scope.row.itemCreatetime }}</template>
                        </el-table-column>
                        <el-table-column prop="itemSortWeight" label="排序" show-overflow-tooltip width="80">
                            <template slot-scope="scope">
                                <el-input v-model.number="scope.row.itemSortWeight" size="mini" placeholder="请输入排序" @blur="updateSort(scope.row)"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="right" width="250">
                            <template slot-scope="scope">
                                <el-button size="mini" @click.stop="topping(scope.row)" v-if="scope.row.itemToppingSort==null">置顶</el-button>
                                <el-button size="mini" @click.stop="cancelTopping(scope.row)" v-else>取消置顶</el-button>
                                <el-button size="mini" @click.stop="editnews(scope.row)">修改</el-button>
                                <el-button size="mini" @click.stop="delEnterpriseNewsAlbumItem(scope.row)" type="danger" plain>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        <el-divider></el-divider>
                        <el-pagination v-if="newsObj.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="newsObj.size" :total="newsObj.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                        </el-pagination>
                    </div>
                </template>
                <div class="text-center" v-else>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">
                        当前您还没有新闻哦！
                    </div>
                    <el-button type="default" size="mini" @click="$oucy.go('/enter/newsSave')">立即发布</el-button>
                </div>
            </div>
        </el-card>
        <!-- 编辑新闻分类 添加新闻分类 -->
        <el-dialog width="500px" :title="classify.id?'编辑新闻分类':'添加新闻分类'" :visible.sync="classifyDialogFormVisible">
            <el-form :model="classify" ref="classifyForm" :rules="classifyFormRule" label-width="120px">
                <el-form-item label="封面" prop="newsAlbumPhoto">
                    <upload-image @uploadImageBack="uploadImageBack" class="avatar-uploader" size="mini">
                        <img v-if="classify.newsAlbumPhoto" :src="$oucy.ossUrl+classify.newsAlbumPhoto" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </upload-image>
                </el-form-item>
                <el-form-item label="名称" prop="newsAlbumName">
                    <el-input v-model="classify.newsAlbumName" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitClassifyForm">保 存</el-button>
                    <el-button @click="classifyDialogFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { enterpriseNewsAlbum, enterpriseNewsAlbumItem } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Photo",
    components: {
    },
    data() {
        return {
            // 下面是验证的
            classifyFormRule: {
                newsAlbumName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                newsAlbumPhoto: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ]
            },
            hasClass: true,
            hasList: true,
            enterpriseId: null,
            enterpriseNewsAlbumId: null,
            // 新闻册
            classifyDialogFormVisible: !true,
            classifyList: [],
            classify: {
                newsAlbumName: null,
                newsAlbumPhoto: null,
            },
            // 请求对象
            query: {
                enterpriseNewsAlbumId: null,
                enterpriseId: null,
                limit: 10,
                start: 0,

            },
            // 返回对象
            newsObj: {}

        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.getAllEnterpriseNewsAlbumsClient()
    },
    methods: {

        handleSelectionChange() {},
        // 点击保存分类册
        submitClassifyForm() {

            const me = this;
            me.$refs["classifyForm"].validate((valid) => {
                if (valid) {
                    this.classify.enterpriseId = this.enterpriseId
                    if (this.classify.id) {
                        this.updateEnterpriseNewsAlbum()
                    } else {
                        this.addEnterpriseNewsAlbum()
                    }
                }
            })

        },
        delMessageBox(fu, v, i, text = null) {
            this.$confirm(text || '此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v, i)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });

            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 增加分类
        addClassify() {
            this.classify = {
                newsAlbumName: null,
                newsAlbumPhoto: null,
            }
            this.oPenClassifyDialogFormVisible()
        },
        // 编辑分类
        editClassify(v) {
            this.classify = Object.assign({}, v)
            this.oPenClassifyDialogFormVisible()
        },
        // 打开添加分类
        oPenClassifyDialogFormVisible() {
            this.classifyDialogFormVisible = true
        },
        // 关闭添加分类
        closeClassifyDialogFormVisible(v) {
            this.classifyDialogFormVisible = false
        },
        // 上传回调
        uploadImageBack(v) {
            this.classify.newsAlbumPhoto = v
        },
        // 新增企业新闻册
        addEnterpriseNewsAlbum() {
            enterpriseNewsAlbum.addEnterpriseNewsAlbum(this.classify).then(res => {
                console.log(res)
                this.getAllEnterpriseNewsAlbumsClient()
                this.closeClassifyDialogFormVisible()
            })
        },
        // 删除企业新闻册
        delEnterpriseNewsAlbum(v, i) {
            enterpriseNewsAlbum.delEnterpriseNewsAlbum({ id: v.id, enterpriseId: this.enterpriseId }).then(res => {
                this.classifyList.splice(i, 1)
                // 如果删除的是当前选中的分类
                if (this.enterpriseNewsAlbumId == v.id) {
                    if (this.classifyList.length > 0) {
                        this.enterpriseNewsAlbumId = this.classifyList[0].id
                        this.getAllEnterpriseNewsAlbumItems()
                    }
                }
            })
        },
        // 修改企业新闻册
        updateEnterpriseNewsAlbum() {
            enterpriseNewsAlbum.updateEnterpriseNewsAlbum(this.classify).then(res => {
                console.log(res)
                this.getAllEnterpriseNewsAlbumsClient()
                this.closeClassifyDialogFormVisible()

            })
        },
        // 查询用户的新闻册
        getAllEnterpriseNewsAlbumsClient() {
            enterpriseNewsAlbum.getAllEnterpriseNewsAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                if (res) {
                    this.classifyList = res
                    if (!this.enterpriseNewsAlbumId && this.classifyList && this.classifyList.length) {
                        this.enterpriseNewsAlbumId = this.classifyList[0].id
                        this.getAllEnterpriseNewsAlbumItems()
                    }
                }
                console.log(res)
            })
        },
        // 查询新闻
        getAllEnterpriseNewsAlbumItems() {
            this.query.enterpriseNewsAlbumId = this.enterpriseNewsAlbumId
            this.query.enterpriseId = this.enterpriseId
            enterpriseNewsAlbumItem.getAllEnterpriseNewsAlbumItems(this.query).then(res => {
                this.newsObj = res
            })
        },
        sizeChange: function(pageSize) {
            const me = this;
            me.query.limit = pageSize;
            me.getAllEnterpriseNewsAlbumItems();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.query.start = current - 1;
            me.getAllEnterpriseNewsAlbumItems();
        },
        // 置顶
        topping(v){
            enterpriseNewsAlbumItem.topping({id:v.id}).then(res=>{
                this.getAllEnterpriseNewsAlbumItems()
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            })
        },
        // 取消置顶
        cancelTopping(v){
            enterpriseNewsAlbumItem.cancelTopping({id:v.id}).then(res=>{
                this.getAllEnterpriseNewsAlbumItems()
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            })
        },
        // 删除企业新闻
        delEnterpriseNewsAlbumItem(v,i){
            enterpriseNewsAlbumItem.delEnterpriseNewsAlbumItem({id:v.id}).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.getAllEnterpriseNewsAlbumItems()
            })
        },
        // 修改排序
        updateSort(v,i){
            enterpriseNewsAlbumItem.updateSort({id:v.id,itemSortWeight:v.itemSortWeight}).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.getAllEnterpriseNewsAlbumItems()
            })
        },
        editnews(v){
            this.$oucy.go('/enter/newsSave?id='+v.id)
        },

    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}
</style>